import axios from "../api-v2";

export const getProfile = () => {
  return axios.get(`/user/me`, {
    params: { source: "web" },
  });
};

export const updateProfile = (data) => {
  return axios.put(`/user/update-profile`, data);
};

export const closeAccount = (data) => {
  return axios.post(`/user/close-account`, data);
};

export const createShippingAddress = (data) => {
  return axios.post(`/user/add-shipping-address`, data);
};

export const updateShippingAddress = (id, data) => {
  return axios.patch(`/user/update-shipping-address/${id}`, data);
};

export const deleteShippingAddress = (id) => {
  return axios.delete(`/user/remove-shipping-address/${id}`);
};

export const fetchWallets = () => {
  return axios.get(`/wallet`);
};

export const fetchBankAcounts = () => {
  return axios.get(`/bank-details`);
};

export const createBankAccount = (payload) => {
  return axios.post(`/bank-details`, payload);
};

export const updateBankAccount = (id, payload) => {
  return axios.patch(`/bank-details/${id}`, payload);
};

export const listBankDetails = () => {
  return axios.patch(`/bank-details`);
};
