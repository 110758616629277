import { DELIVERY_TYPES } from "contexts/constants";
import { convertValueToSelectOption } from "utilities";
import * as yup from "yup";

export const TICKET_TYPE_VALUES = {
  Free: "Free",
  Paid: "Paid",
};

export const TICKET_TYPES = [TICKET_TYPE_VALUES.Free, TICKET_TYPE_VALUES.Paid];

export const initialValues = {
  deliveryType: convertValueToSelectOption(DELIVERY_TYPES[0]),
};

export const validationSchema = yup.object().shape({
  name: yup.string().label("Ticket name").required(),
  quantity: yup
    .number()
    .label("Quantity")
    .required()
    .integer("Quantity must be an integer"),
  // .min(1, "Quantity must be at least 1")
  // .max(1000000, "Quantity cannot exceed 10,000"),
  type: yup.object().label("Ticket Type").required(),
  price: yup
    .string()
    .label("Ticket price")
    .when("type", (type, schema) => {
      if (type?.value !== TICKET_TYPE_VALUES.Free) {
        return schema
          .required()
          .test(
            "is-valid-price",
            "Price must be less than or equal to 10,000",
            (value) => {
              return Number(value) <= 10000;
            }
          );
      }
      return schema;
    }),
  timeSlot: yup.object().label("Time Slot").required(),
  deliveryType: yup.object().label("Delivery Type").required(),
});

export const TIME_SLOTS = ["All Time"];

export const SELECT_FORM_KEYS = ["timeSlot", "deliveryType", "type"];
