import { PLATFORM_FUEL_TYPES } from "../../../../constant";
import SelectFuelStationPane from "../../../components/shared/selectFuelStationPane";
import PageTitleHeader from "../../../components/shared/titleHeader";
import { LocationIcon } from "assets/icons";
import EmptyTable from "components/pages/authenticated-new/pages/Events/shared/EmptyTable";
import { Button } from "components/shared/Button";
import Loader from "components/shared/Loader";
import { ROUTE_ENUMS } from "enums";
import usePagination from "hooks/usePagination";
import React, { Fragment, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom-v5-compat";
import { fetchFuelStationRequest } from "state/slices/fuel";

const FuelBuyerSelectFuelStationPage = () => {
  const [search, setSearch] = useState("");
  const [selectedFuelType, setSelectedFuelType] = useState("");
  const [selectedStations, setSelectedStations] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const fuelTypes = PLATFORM_FUEL_TYPES;

  const handleSearch = (e) => setSearch(e.target.value);

  const handleFuelTypeChange = (e) => setSelectedFuelType(e.target.value);

  const handleStationChange = (station) => {
    setSelectedStations((prevSelected) => {
      const isSelected = prevSelected.some((s) => s?.id === station?.id);
      return isSelected
        ? prevSelected.filter((s) => s?.id !== station?.id)
        : [...prevSelected, station];
    });
  };

  const { data = [], isLoading } = usePagination({
    dataSelector: "",
    request: fetchFuelStationRequest,
    customQueryPayload: {},
    customQueryParams: { ...(search && { search }) },
  });

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    setSelectedStations(isChecked ? data : []);
  };

  const handleNext = () => {
    const queryString = new URLSearchParams(location.search).toString();
    const url = `${ROUTE_ENUMS.DASHBOARD_FUEL_STATION}/purchase/order${
      queryString ? `?${queryString}` : ""
    }`;
    navigate(url, { state: { selectedStations } });
  };

  const pickedFuelStations = location.state?.selectedStations || [];
  const isEmptySelection = selectedStations.length === 0;

  const filteredStations = selectedFuelType
    ? data.filter(
        (station) =>
          station.products &&
          Array.isArray(station.products) &&
          station.products.some(
            (product) =>
              product.type.toLowerCase() ===
              selectedFuelType.toLocaleLowerCase()
          )
      )
    : data;

  return (
    <>
      <PageTitleHeader
        title="Select Fuel Station"
        description="Select the fuel station you wish to purchase your petrol from in your given location. You can either choose one or more available petrol stations."
        withBackNavigation
      />

      <div className="tw-mt-[2em]">
        <div className="tw-flex tw-flex-col tw-gap-4 md:tw-flex-row lg:tw-flex-row tw-items-center tw-justify-between ">
          <div className="tw-w-full md:tw-w-[755px] lg:tw-w-[755px] tw-border tw-border-3-gray-700 tw-rounded-md tw-py-3 tw-px-4 tw-flex tw-gap-3 md:tw-items-center lg:tw-items-center ">
            <div
              className="tw-flex tw-items-center tw-justify-between md:tw-w-[172px] lg:tw-w-[172px] tw-px-5"
              style={{ borderRight: "1px solid #C4C4C4" }}
            >
              <p className="tw-hidden md:tw-block lg:tw-block tw-m-0">
                Location
              </p>
              <LocationIcon />
            </div>
            <div>
              <input
                type="search"
                value={search}
                onChange={handleSearch}
                placeholder="Search location"
                className="tw-bg-transparent tw-w-full tw-text-sm sm:tw-text-sm md:tw-text-md lg:tw-text-md tw-outline-none"
              />
            </div>
          </div>

          <div className="tw-w-full md:tw-w-[400px] lg:tw-w-[400px]">
            <select
              value={selectedFuelType}
              onChange={handleFuelTypeChange}
              className="tw-w-full tw-outline-none tw-cursor-pointer tw-bg-transparent tw-border tw-border-gray-700 tw-rounded-md tw-py-4 tw-px-3"
            >
              <option value="">Select Fuel Type</option>
              {fuelTypes.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="tw-py-10 tw-px-5 tw-mt-5 tw-rounded-md tw-bg-white">
        {filteredStations.length > 0 && (
          <div>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="tw-ml-2 tw-font-semibold">Select All</span>
          </div>
        )}
        <div className="tw-mt-8">
          {!isLoading ? (
            <>
              {filteredStations.length > 0 ? (
                filteredStations.map((station, key) => {
                  const { id, name, photoUrl, products = [] } = station;
                  return (
                    <Fragment key={key}>
                      <SelectFuelStationPane
                        name={name}
                        src={photoUrl}
                        price={products[0] ? products[0]?.price : "N/A"}
                        onSelect={() => handleStationChange(station)}
                        isSelected={
                          selectedStations.some((s) => s?.id === id) ||
                          pickedFuelStations.some((s) => s?.id === id)
                        }
                      />
                    </Fragment>
                  );
                })
              ) : (
                <EmptyTable
                  title="No Fuel Station(s) Available"
                  description="Once any fuel station(s) is added, they will appear here."
                  className="tw-mb-6"
                />
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>

        {filteredStations.length > 0 && (
          <div className="tw-flex tw-items-center tw-gap-3 tw-justify-end">
            <Button variant="outline">Cancel</Button>
            <Button
              className="tw-bg-secondary-700 tw-text-white"
              onClick={handleNext}
              disabled={isEmptySelection}
            >
              Next
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default FuelBuyerSelectFuelStationPage;
