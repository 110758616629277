import DriversRegPanel from "../../../components/shared/drivers/driversRegPanel";
import { FUEL_TYPES } from "../../Drivers/Form/constants";
import {
  CODE_VALIDITY_VARIANTS,
  SELECT_CUSTOM_CONTROL_STYLES,
  buyFuelSchema,
} from "../constants";
import { EditIcon } from "assets/icons";
import { Button } from "components/shared/Button";
import TextInput from "components/shared/forms/TextInput";
import ThemedSelect from "components/shared/forms/ThemedSelect";
import { ROUTE_ENUMS } from "enums";
import { Formik, Field, Form } from "formik";
import usePagination from "hooks/usePagination";
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import {
  createFuelBuyerRequest,
  fetchPaginatedDriversRequest,
  fetchPaginatedVehiclesRequest,
} from "state/slices/fuel";
import {
  convertModelArrayToSelectOptions,
  getOneLinerErrorMessage,
} from "utilities";

const FuelBuyerPurchaseOrderPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = React.useState();
  const [selectedDriver, setSelectedDriver] = useState(null);
  const selectedStations = location.state?.selectedStations || [];
  const [isOtherPurchase, setIsOtherPurchase] = useState(false);

  useEffect(() => {
    const purchaseType = searchParams.get("purchase_type");
    setIsOtherPurchase(purchaseType === "other");
  }, [searchParams]);

  const editSelectedFuelStations = () =>
    navigate(
      `${ROUTE_ENUMS.DASHBOARD_FUEL_STATION_PURCHASE}/select-fuelstation`,
      {
        state: { selectedStations },
      }
    );

  const fuelStationIds = selectedStations.map((stations) => {
    return stations.id;
  });

  const fallBackUrl = `${window.location.origin}/dashboard/fuel-station/purchase/generate-code`;

  const createPurchase = async (formValues, formikHelpers) => {
    setIsLoading(true);
    const payload = {
      data: {
        ...formValues,
        productType: formValues.productType.value?.toUpperCase(),
        redirectUrl: fallBackUrl,
        fuelStationIds,
      },
    };

    if (!isOtherPurchase) {
      payload.data.vehicleId = formValues?.vehicleId?.id;
      payload.data.driverId = formValues?.driverId?.id;
    }

    const resultAction = await dispatch(createFuelBuyerRequest(payload.data));

    if (createFuelBuyerRequest.fulfilled.match(resultAction)) {
      formikHelpers.resetForm();
      setIsLoading(false);
      toast.success("Successfully created purchase order");
      const { payload } = resultAction;
      if (payload?.paymentUrl) {
        window.location.href = payload.paymentUrl;
      } else {
        toast.error("Payment URL not found. Please try again.");
      }
    } else {
      setIsLoading(false);
      toast.error(
        resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  const { data: vehicles, isLoading: isLoadingVehicles } = usePagination({
    dataSelector: "",
    request: fetchPaginatedVehiclesRequest,
  });

  const { data: drivers, isLoading: isLoadingDriver } = usePagination({
    dataSelector: "",
    request: fetchPaginatedDriversRequest,
  });

  const vehiclesOption = convertModelArrayToSelectOptions(
    vehicles,
    "id",
    "licenseNo",
    true
  );

  const driversOption = convertModelArrayToSelectOptions(
    drivers,
    "id",
    "name",
    true
  );

  const handleDriverChange = (selectedOption, setFieldValue) => {
    setFieldValue("driverId", selectedOption);
    setSelectedDriver(selectedOption);
  };

  const { user } = useSelector((state) => state.users.data);

  const initialValues = {
    buyerName: `${user?.firstName} ${user?.lastName}` || "",
    email: `${user?.email}` || "",
    productType: "",
    amount: "",
    codeValidity: 24,
  };

  if (!isOtherPurchase) {
    initialValues.driverId = "";
  }

  return (
    <div className=" tw-flex tw-flex-wrap tw-gap-5 tw-justify-between">
      <div
        className="tw-bg-white   tw-w-full   md:tw-w-[30%] lg:tw-w-[35%] tw-py-8 tw-px-5 md:tw-px-5 lg:tw-px-5 tw-rounded-lg  tw-border tw-border-3-gray-700
                tw-h-fit"
      >
        <div className="tw-flex tw-justify-between">
          <div>
            <small className="tw-font-semibold">Fuel Stations</small>
            {/* <p>Victoria Island</p> */}
          </div>

          <div className="tw-cursor-pointer" onClick={editSelectedFuelStations}>
            <EditIcon />
          </div>
        </div>

        <div className="tw-mt-8">
          {selectedStations?.map((station, key) => {
            return (
              <Fragment key={key}>
                <div className="tw-flex tw-justify-between tw-mt-2 tw-items-center tw-h-20 tw-px-3 md:tw-px-4 lg:tw-px-4 tw-rounded-lg tw-bg-gray-50 tw-cursor-pointer">
                  <div className="tw-flex tw-items-center tw-gap-4 tw-h-full tw-m-0">
                    <div
                      className="tw-w-[55px] tw-h-[55px] tw-rounded-full "
                      style={{
                        backgroundImage: `url(${station?.photoUrl})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                    <p className="tw-text-[12px] tw-m-0 tw-font-semibold">
                      {station?.name}
                    </p>
                  </div>
                  <div className="tw-h-full tw-flex tw-items-center tw-m-0">
                    <p className="tw-font-semibold tw-text-sm tw-m-0">
                      N{station?.price}/ltr
                    </p>
                  </div>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>

      <section
        className="tw-bg-white tw-py-8 tw-px-5 md:tw-px-8 lg:tw-px-8 tw-rounded-md
            tw-w-full   md:tw-w-[60%] lg:tw-w-[60%] "
      >
        <h4 className="tw-font-semibold">Purchase Summary</h4>

        <div className="tw-mt-[35px]">
          <Formik
            initialValues={initialValues}
            validationSchema={buyFuelSchema}
            onSubmit={createPurchase}
            enableReinitialize
          >
            {({ handleSubmit, values, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="">
                    <div className="form-group tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8 ">
                      <div>
                        <Field
                          component={TextInput}
                          className="tw-input"
                          name="buyerName"
                          id="buyerName"
                          placeholder="John Doe"
                          label="Buyer Name"
                          value={values?.buyerName}
                          required
                        />
                      </div>

                      <div>
                        <Field
                          component={TextInput}
                          name="email"
                          id="email"
                          className="tw-input"
                          placeholder="Johndoe@gmail.com"
                          label="Email"
                          values={values?.email}
                          required
                        />
                      </div>
                    </div>

                    <div className=" form-group tw-mt-7 tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8">
                      <div>
                        <Field
                          component={TextInput}
                          name="amount"
                          id="amount"
                          className="tw-input"
                          placeholder="0.00"
                          label="Amount"
                          required
                        />
                      </div>
                      <div>
                        <Field
                          component={ThemedSelect}
                          name="productType"
                          id="productType"
                          placeholder="Petrol"
                          label="Fuel Type"
                          options={FUEL_TYPES}
                          customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                          required
                        />
                      </div>
                    </div>
                    {/* To purchase fuel for a driver/ vehicle  */}
                    {!isOtherPurchase && (
                      <div className="form-group tw-mt-7 tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8">
                        <div>
                          <Field
                            component={ThemedSelect}
                            name="vehicleId"
                            id="vehicleId"
                            placeholder="Select vehicle"
                            label="Vehicle"
                            customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                            options={vehiclesOption}
                            required
                          />
                        </div>

                        <div className="">
                          <Field
                            component={ThemedSelect}
                            name="driverId"
                            id="driverId"
                            placeholder="Select Driver"
                            label="Driver"
                            customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                            options={driversOption}
                            onChange={(option) =>
                              handleDriverChange(option, setFieldValue)
                            }
                            required
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {!isOtherPurchase && selectedDriver && (
                    <DriversRegPanel
                      name={selectedDriver?.label || ""}
                      licenseNumber={selectedDriver?.licenseNo || ""}
                      driversPhoto={selectedDriver?.photoUrl || ""}
                    />
                  )}

                  <div className="tw-flex tw-gap-3 tw-justify-end tw-items-center tw-mt-6">
                    <Button
                      className="tw-px-4"
                      onClick={() => window.history.back()}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      className="tw-border-none tw-px-4 tw-rounded-md tw-bg-secondary-700 tw-text-white"
                      variant="rounded"
                      loading={isLoading}
                      // disabled={isLoading || !isValid}
                    >
                      {isLoading ? "Generating..." : "Generate Code"}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
    </div>
  );
};

export default FuelBuyerPurchaseOrderPage;
