import { PLATFORM_FUEL_TYPES } from "../../../../constant";
import { FUEL_PRODUCTS } from "constants/global";
import * as yup from "yup";

export const initialValues = {
  name: "",
  email: "",
  phoneNumber: "",
  licenseNo: "",
};

export const validationSchema = yup.object().shape({
  name: yup.string().label("Name").required(),
  email: yup.string().label("Email").required(),
  licenseNo: yup.string().label("License Number").required(),
  phoneNumber: yup
    .string()
    .label("Contact Number")
    .matches(/^([0-9\s\-\+\(\)]*)$/, "Phone number is invalid"),
});

export const CAR_TYPES = ["Car", "Bus", "SUV"].map((item) => ({
  value: item,
  label: item,
}));

export const FUEL_TYPES = PLATFORM_FUEL_TYPES.map((item) => ({
  value: item,
  label: item,
}));

export const SELECT_CUSTOM_CONTROL_STYLES = {
  padding: "4px",
  borderRadius: "10px",
  border: "1px solid #d1d5db",
};
