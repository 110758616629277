import {
  PLATFORM_FUEL_TYPES,
  PLATFORM_VEHICLE_TYPES,
} from "../../../../constant";
import * as yup from "yup";

export const initialValues = {
  manufacturer: "",
  manufacturerYear: "",
  model: "",
  licenseNo: "",
  type: "",
};

export const validationSchema = yup.object().shape({
  type: yup.object().label("Type").required(),
  fuelType: yup.object().label("Fuel Type").required(),
  manufacturer: yup.string().label("Manufacturer"),
  manufacturerYear: yup.string().label("Manufacturer Yeat").required(),
  model: yup.string().label("Model").required(),
  licenseNo: yup.string().label("Registration Number (License)").required(),
  ownerId: yup.string().label("Owner "),
  ownerType: yup.string().label("Owner Type"),
});

export const CAR_TYPES = PLATFORM_VEHICLE_TYPES.map((item) => ({
  value: item,
  label: item,
}));

export const FUEL_TYPES = PLATFORM_FUEL_TYPES.map((item) => ({
  value: item,
  label: item,
}));

export const SELECT_CUSTOM_CONTROL_STYLES = {
  padding: "4px",
  borderRadius: "10px",
  border: "1px solid #d1d5db",
};
