import {
  VehicleActiveIcon,
  DashboaradActiveAvatarIcon,
  GasStationActiveIcon,
} from "assets/icons/dashboardIcons";
import { Button } from "components/shared/Button";
import usePagination from "hooks/usePagination";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom-v5-compat";
import { fetchBuyerDashboardRequest } from "state/slices/fuel";
import { updateFuelCustomerDataAction } from "state/slices/users";

const styled = {
  boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.04)",
};

const FuelStationDashboardLayout = (props) => {
  const dispatch = useDispatch();
  const { data } = usePagination({
    request: fetchBuyerDashboardRequest,
    dataSelector: "",
    customQueryPayload: {},
  });

  const { drivers, vehicles, orders } = data;
  console.log(data);

  const matricsData = [
    {
      title: "Total Vehicles",
      icon: <VehicleActiveIcon />,
      count: Number(vehicles) || 0,
      color: "#944042",
    },
    {
      title: "Total Drivers",
      icon: <DashboaradActiveAvatarIcon />,
      count: Number(drivers) || 0,
      color: "#205076",
    },
    {
      title: "Total Transactions",
      icon: <GasStationActiveIcon />,
      count: Number(orders) || 0,
      color: "#A8B5CA",
    },
  ];

  const { user } = useSelector((state) => state.users.data);

  useEffect(() => {
    dispatch(updateFuelCustomerDataAction(data));
  });

  return (
    <div className="container tw-mx-auto">
      <div className="tw-flex tw-flex-col md:tw-flex-row lg:tw-flex-row tw-items-center tw-justify-between tw-mb-[2em]">
        <h3 className="tw-font-semibold tw-text-black">
          Welcome, {data?.firstName}
        </h3>

        <div className="tw-flex tw-items-center tw-gap-3">
          <Link to={"/dashboard/fuel-station/vehicles/create"}>
            <Button variant="primary">Add a Vehicle</Button>
          </Link>
          {/* <Button variant="primary">Buy now</Button> */}
        </div>
      </div>

      <div className="tw-my-3 tw-grid tw-grid-cols-2 tw-gap-[1.9px] lg:tw-grid-cols-1 lg:tw-grid-rows-1 lg:tw-flex lg:tw-flex-row">
        {matricsData.map((data, index, array) => {
          const isFirstArray = index === 0;
          const isLastArray = index === array.length - 1;
          return (
            <div
              className={`
                tw-font-semibold
                tw-p-[20px] tw-h-[155px] tw-w-full tw-flex tw-flex-col
                tw-justify-between
                ${
                  isFirstArray ? "md:tw-rounded-l-xl lg:md:tw-rounded-l-xl" : ""
                }
                ${isLastArray ? "md:tw-rounded-r-xl lg:md:tw-rounded-r-xl" : ""}
              `}
              style={{ backgroundColor: data.color }}
              key={index}
            >
              <div className="tw-flex tw-items-center tw-justify-between tw-gap-2">
                <h6 className="tw-text-white tw-font-semibold">{data.title}</h6>
                <div
                  className="tw-bg-white tw-w-[32px] tw-h-[32px] tw-rounded-full tw-flex
                tw-justify-center tw-items-center"
                >
                  {data.icon}
                </div>
              </div>

              <h2 className="tw-text-white tw-font-semibold ">{data.count}</h2>
            </div>
          );
        })}
      </div>
      <div className=" tw-flex tw-flex-col md:tw-flex-row lg:tw-flex-row tw-gap-3">
        <div
          className="tw-bg-white tw-w-full md:tw-w-[75%]
          tw-border tw-border-gray-200 lg:tw-w-[75%] tw-py-[25px] tw-rounded-lg"
          style={styled}
        >
          <h5 className="tw-font-medium tw-px-5 tw-text-gray-500">
            Recent Transaction History{" "}
          </h5>
          <div className="tw-my-5">{props.children}</div>
        </div>
        <div
          className="tw-bg-white tw-w-full md:tw-w-[405px]
          tw-border tw-border-gray-200 lg:tw-w-[405px] tw-py-[25px] tw-rounded-lg"
          style={styled}
        >
          <div className="tw-w-[90%] tw-mx-auto">{props.others}</div>
        </div>
      </div>
    </div>
  );
};

FuelStationDashboardLayout.propTypes = {
  children: PropTypes.element,
  others: PropTypes.element,
  withMetrics: PropTypes.bool,
};

export default FuelStationDashboardLayout;
