import { initialValues, validationSchema } from "./constants";
import { CAR_TYPES } from "./constants";
import { FUEL_TYPES } from "./constants";
import { SELECT_CUSTOM_CONTROL_STYLES } from "./constants";
import PlainFuelStationDashboardLayout from "components/layout/FuelStation/plainFuelStationDashboardContainer";
import EmptyTable from "components/pages/authenticated-new/pages/Events/shared/EmptyTable";
import { Button } from "components/shared/Button";
import TextInput from "components/shared/forms/TextInput";
import ThemedSelect from "components/shared/forms/ThemedSelect";
import { ROUTE_ENUMS } from "enums";
import { Field, Form, Formik } from "formik";
import usePagination from "hooks/usePagination";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import {
  createVehicleRequest,
  fetchPaginatedDriversRequest,
  fetchVehicleRequest,
  updateVehicleRequest,
} from "state/slices/fuel";
import { fetchVehicleManufacturerRequest } from "state/slices/fuel";
import {
  convertModelArrayToSelectOptions,
  getAPIErrorsForFormik,
  getOneLinerErrorMessage,
} from "utilities";

const FuelBuyerVehiclesFormPage = () => {
  const { push } = useHistory();
  const navigate = useHistory();
  const { id } = useParams();
  const asEdit = !!id;
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.users.data);
  const [vehicle, setVehicle] = useState({});
  const [isFetchingVehicle, setIsFetchingVehicle] = useState(false);
  const [manufacturerData, setManufacturerData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getVehicle = useCallback(async () => {
    if (!id) return;

    setIsFetchingVehicle(true);
    try {
      const resultAction = await dispatch(fetchVehicleRequest(id));
      if (fetchVehicleRequest.fulfilled.match(resultAction)) {
        setVehicle(resultAction.payload);
      } else {
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    } catch (error) {
      toast.error("Failed to fetch vehicle details");
    } finally {
      setIsFetchingVehicle(false);
    }
  }, [dispatch, id]);

  useEffect(() => {
    getVehicle();
  }, [getVehicle]);

  const createVehicle = async (formValues, formikHelpers) => {
    setIsLoading(true);
    try {
      const payload = {
        ...formValues,
        type: formValues.type?.value,
        fuelType: formValues.fuelType?.value,
        ownerId: user?.id,
        ownerType: "COMPANY",
        driverId: formValues.driverId?.value,
        manufacturer: manufacturerData,
      };

      const resultAction = await dispatch(createVehicleRequest(payload));
      if (createVehicleRequest.fulfilled.match(resultAction)) {
        toast.success("Successfully added vehicle");
        formikHelpers.resetForm();
        push("/dashboard/fuel-station/vehicles");
      } else {
        if (resultAction.payload?.message) {
          toast.error(resultAction.payload.message);
        } else {
          formikHelpers.setErrors(getAPIErrorsForFormik(resultAction.payload));
          toast.error(
            getOneLinerErrorMessage(resultAction.payload) ||
              resultAction.error?.message ||
              "An error occurred, please try again."
          );
        }
      }
    } catch (error) {
      toast.error("Failed to create vehicle");
    } finally {
      setIsLoading(false);
    }
  };

  const editVehicle = async (formValues, formikHelpers) => {
    setIsLoading(true);
    try {
      const payload = {
        ...formValues,
        type: formValues.type?.value,
        fuelType: formValues.fuelType?.value,
        manufacturer: formValues.manufacturer?.value,
      };

      const resultAction = await dispatch(updateVehicleRequest(payload));
      if (updateVehicleRequest.fulfilled.match(resultAction)) {
        toast.success("Successfully updated vehicle");
        formikHelpers.resetForm();
        push("/dashboard/fuel-station/vehicles");
      } else {
        if (resultAction.payload?.message) {
          toast.error(resultAction.payload.message);
        } else {
          formikHelpers.setErrors(getAPIErrorsForFormik(resultAction.payload));
          toast.error(
            getOneLinerErrorMessage(resultAction.payload) ||
              resultAction.error?.message ||
              "An error occurred, please try again."
          );
        }
      }
    } catch (error) {
      toast.error("Failed to update vehicle");
    } finally {
      setIsLoading(false);
    }
  };

  const { data: vehicleManufacturalData = { data: [] } } = usePagination({
    dataSelector: "",
    request: fetchVehicleManufacturerRequest,
    customQueryPayload: {},
  });

  const { data: driversData = [] } = usePagination({
    dataSelector: "",
    request: fetchPaginatedDriversRequest,
    customQueryPayload: {},
  });

  const convertDriversData = convertModelArrayToSelectOptions(
    driversData,
    "id",
    "name",
    true
  );

  const addDrivers = () =>
    navigate.push(`${ROUTE_ENUMS.DASHBOARD_FUEL_STATION_DRIVERS}/create`);

  const listManufacturer = vehicleManufacturalData?.data || [];

  const manufacturerOptions = listManufacturer.map((item) => ({
    label: item,
    value: item,
  }));

  const convertManufacturalList = convertModelArrayToSelectOptions(
    manufacturerOptions,
    "label",
    "value",
    true
  );

  const defaultValues = {
    ...initialValues,
    manufacturer: manufacturerData,
  };

  const getInitialValues = () => {
    if (asEdit) {
      return {
        ...vehicle,
        fuelType: vehicle?.fuelType
          ? {
              label: vehicle.fuelType,
              value: vehicle.fuelType,
            }
          : null,
        type: vehicle?.type
          ? {
              label: vehicle.type.toUpperCase(),
              value: vehicle.type.toUpperCase(),
            }
          : null,
        manufacturer: vehicle?.manufacturer
          ? {
              label: vehicle.manufacturer,
              value: vehicle.manufacturer,
            }
          : null,
      };
    }
    return defaultValues;
  };

  if (isFetchingVehicle) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <PlainFuelStationDashboardLayout
        header={`${asEdit ? "Edit" : "Add"} Vehicle`}
      >
        <div>
          <Formik
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            onSubmit={asEdit ? editVehicle : createVehicle}
            enableReinitialize
          >
            {({ handleSubmit, isValid, values, errors }) => {
              setManufacturerData(values?.manufacturer?.value);

              return (
                <Form onSubmit={handleSubmit}>
                  <div className="tw-flex tw-flex-col tw-gap-8">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8 tw-gap-y-8">
                      <Field
                        component={ThemedSelect}
                        name="type"
                        id="type"
                        placeholder="Type"
                        options={CAR_TYPES}
                        customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                        required
                      />

                      <div>
                        <Field
                          component={ThemedSelect}
                          name="fuelType"
                          id="fuelType"
                          placeholder="Fuel Type"
                          options={FUEL_TYPES}
                          customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8 tw-gap-y-8">
                      <div>
                        <Field
                          component={ThemedSelect}
                          name="manufacturer"
                          id="manufacturer"
                          placeholder="Manufacturer"
                          options={convertManufacturalList}
                          customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                          required
                        />
                      </div>

                      <div>
                        <Field
                          component={TextInput}
                          className="tw-input"
                          name="licenseNo"
                          id="licenseNo"
                          type="text"
                          placeholder="Vehicle Reg/Licence No"
                        />
                      </div>

                      <div>
                        <Field
                          component={TextInput}
                          className="tw-input"
                          name="model"
                          id="model"
                          type="text"
                          placeholder="Vehicle Model"
                        />
                      </div>

                      <div>
                        <Field
                          component={TextInput}
                          className="tw-input"
                          name="manufacturerYear"
                          id="manufacturerYear"
                          type="text"
                          placeholder="Vehicle Mfg Year"
                        />
                      </div>
                    </div>

                    <div>
                      {driversData?.length >= 1 ? (
                        <Field
                          component={ThemedSelect}
                          name="driverId"
                          id="driverId"
                          placeholder="Select Driver"
                          options={convertDriversData}
                          customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                          required
                        />
                      ) : (
                        <div className="tw-mt-4">
                          <EmptyTable
                            title="No Driver added yet"
                            description="Once any driver(s) is added, they will appear here."
                            className="tw-mb-3"
                            value="Add Driver"
                            onClick={addDrivers}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="tw-flex tw-gap-3 tw-justify-end tw-mt-5">
                    <Button
                      onClick={() => push("/dashboard/fuel-station/vehicles")}
                      type="button"
                      variant="outline"
                      disabled={isLoading}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      variant="primary"
                      loading={isLoading}
                      disabled={isLoading || !isValid}
                    >
                      {isLoading ? "Saving..." : "Submit"}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </PlainFuelStationDashboardLayout>
    </div>
  );
};

export default FuelBuyerVehiclesFormPage;
